import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { updateLanguage } from 'store/apps/app';
import { appSettings } from 'config/app';
import LanguageButton from 'components/common/LanguageButton';
import toast from 'react-hot-toast';

const TopBar = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { language } = useSelector((state) => state.app);

  const { selected: place } = useSelector(state => state.place);

  const { pathname } = useLocation();

  const { i18n } = useTranslation();

  const changeLanguage = (lang) => {
    dispatch(updateLanguage(lang));
    i18n.changeLanguage(lang);
  }

  useEffect(() => {
    if (!place) {
      toast.error(t('messages.error.no-location-assigned'));
      navigate('/');
    }
    // eslint-disable-next-line
  }, [place, pathname])

  return (
    <div className="grid grid-cols-12 gap-x-5 px-8">
      <div className="col-span-5 flex justify-start w-full">
        <img
          src={appSettings.logo.url}
          alt={appSettings.logo.alt}
          onClick={() => navigate('/')}
        />
      </div>
      <div className="col-span-7 flex justify-end items-center w-full gap-x-5">
        <LanguageButton outlined={language !== 'pt'} onClick={() => changeLanguage('pt')} size="md">
          PT
        </LanguageButton>
        <LanguageButton outlined={language !== 'en'} onClick={() => changeLanguage('en')} size="md">
          EN
        </LanguageButton>
      </div>
    </div>
  )
}

export default TopBar;