import React from 'react'
import { tv } from 'tailwind-variants';
import cn from 'classnames'

const LanguageButton = ({ children, size, outlined = false, onClick }) => {

  return (
    <button
      onClick={onClick}
      className={cn(
        button({ size: size, color: outlined ? 'outlined' : 'filled' })
      )}
    >
      {children}
    </button>
  )
}

export default LanguageButton;

const button = tv({
  base: "flex justify-center items-center rounded-full font-light focus:outline-none",
  variants: {
    color: {
      filled: 'bg-gradient-to-r from-lightBlue to-darkBlue text-white border border-primary',
      outlined: 'border border-primary text-primary',
    },
    size: {
      'sm': 'text-base p-2',
      'md': 'text-base h-10 w-10',
      'lg': 'text-lg py-3 px-4',
      'xl': 'text-xl p-3',
      '2xl': 'text-2xl p-4',
      '3xl': 'text-4xl p-5',
      '4xl': 'text-4xl p-5 ',
      '5xl': 'text-5xl p-5',
      '6xl': 'text-6xl p-6',
      '7xl': 'text-7xl p-6',
    }
  },
  defaultVariants: {
    size: "lg",
    color: "dark",
  }
});