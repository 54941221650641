import { createSlice } from '@reduxjs/toolkit';

// Create an async thunk for verifying a PIN
export const placeSlice = createSlice({
  name: 'place',
  initialState: {
    selected: false,
    sizes: false
  },
  reducers: {
    updatePlace: (state, action) => {
      state.selected = action.payload
    }
  },
})

export const { updatePlace } = placeSlice.actions;

export default placeSlice.reducer
